<template>
    <div>
        <div class="pl-6 md:pl-26 pr-2 md:pr-16 pb-20">
            <div class="bg-black">
                <div
                    class="bg-ocean px-5 py-1 sm:py-3 mt-3 relative -left-2 sm:-left-4 -top-2 sm:-top-4 border-2 border-black">
                    <div class="flex justify-start space-x-1 sm:space-x-2 mt-4 pl-2 sm:pl-10">
                        <div class="bg-sunny rounded-full px-3 sm:px-4 py-3 sm:py-4 border-2 border-black">
                        </div>
                        <div class="bg-sunny rounded-full px-3 sm:px-4 py-3 sm:py-4 border-2 border-black">
                        </div>
                    </div>
                    <div class="text-sunny text-center -mt-8 sm:-mt-10 font-Karantina text-3xl font-medium pb-4">
                        <h1>~Contacts</h1>
                    </div>
                </div>
            </div>

            <div class="bg-black">
                <div class="bg-sunny pl-10 py-10 relative -left-2 sm:-left-4 -top-2 sm:-top-4 border-2 border-black">
                    <div class="w-32 sm:w-80 bg-black">
                        <div
                            class="bg-ocean px-4 sm:px-10 py-2 sm:py-6 border-2 border-black relative -left-2 -top-2 text-sunny">
                            <h1 class="font-Karantina text-3xl sm:text-5xl font-medium">CONTACS = [</h1>
                        </div>
                    </div>

                    <div class="block sm:flex justify-around py-10 sm:py-20 font-Karantina font-medium text-3xl">
                        <div id="phone">
                            <div>
                                <div class="flex text-3xl sm:text-4xl">
                                    <h1>{</h1>
                                </div>
                            </div>
                            <div class="ml-8 sm:ml-20 py-2">
                                <div class="pb-3">
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Contact Name : Admin1</h1>
                                </div>
                                <div>
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Phone Number : <a class="text-red-700" href="https://wa.link/wkwri8">Wa-Link</a></h1>
                                </div>
                            </div>
                            <div>
                                <div class="pb-5 text-3xl sm:text-4xl">
                                    <h1>},</h1>
                                </div>
                            </div>
                        </div>

                        <div id="whatsapp">
                            <div>
                                <div class="flex text-3xl sm:text-4xl">
                                    <h1>{</h1>
                                </div>
                            </div>
                            <div class="ml-8 sm:ml-20 py-2">
                                <div class="pb-3">
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Contact Name : Admin2</h1>
                                </div>
                                <div>
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Phone Number : <a class="text-red-700" href="https://wa.link/uca45b">Wa-Link</a></h1>
                                </div>
                            </div>
                            <div>
                                <div class="pb-5 text-3xl sm:text-4xl">
                                    <h1>},</h1>
                                </div>
                            </div>
                        </div>

                        <div id="socialMed">
                            <div>
                                <div class="flex text-3xl sm:text-4xl">
                                    <h1>{</h1>
                                </div>
                            </div>
                            <div class="ml-8 sm:ml-20 py-2">
                                <div class="pb-3">
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Contact Name : Admin3</h1>
                                </div>
                                <div>
                                    <h1 class="tracking-wider text-2xl sm:text-4xl">Phone Number : <a class="text-red-700" href="https://wa.link/pax1ry">Wa-Link</a></h1>
                                </div>
                            </div>
                            <div>
                                <div class="pb-0 sm:pb-5 text-3xl sm:text-4xl">
                                    <h1>},</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-end pr-10 sm:pr-20">
                        <div class="font-Karantina text-4xl sm:text-5xl font-bold">
                            ]
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>