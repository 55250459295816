<template>
    <div>
        <div class="pl-6 md:pl-26 pr-2 md:pr-16 pb-20">
            <div class="bg-black">
                <div class="bg-ocean mt-3 relative -left-2 sm:-left-4 -top-2 sm:-top-4 border-2 border-black">
                    <div class="flex justify-start space-x-1 sm:space-x-2 mt-4 pl-4 sm:pl-10">
                        <div class="bg-sunny rounded-full px-3 sm:px-4 py-3 sm:py-4 border-2 border-black">
                        </div>
                        <div class="bg-sunny rounded-full px-3 sm:px-4 py-3 sm:py-4 border-2 border-black">
                        </div>
                    </div>
                    <div>
                        <div class="text-sunny text-center -mt-8 sm:-mt-10 font-Karantina text-3xl font-medium pb-4">
                            <h1>~About</h1>
                        </div>
                    </div>
                    <div>
                        <div
                            class="bg-sunny font-Karantina border-t-4 border-black px-6 sm:px-10 py-4 sm:py-10 text-2xl">
                            <div
                                class="pl-2 sm:pl-16 grid gap-y-6 sm:gap-y-0 grid-cols-1 sm:grid-cols-3 text-2xl sm:text-3xl capitalize text-sunny tracking-wider pt-6">
                                <div class="flex">
                                    <div class="bg-black">
                                        <div
                                            class="relative -top-2 -left-2 bg-ocean border-2 border-black px-10 sm:px-0  w-64 sm:w-64 py-2 sm:py-10 text-center">
                                            <h1><span class="text-maryjane text-4xl">120</span> trips with clients</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="bg-black">
                                        <div
                                            class="relative -top-2 -left-2 bg-ocean border-2 border-black px-10 sm:px-0  w-64 sm:w-64 py-2 sm:py-10 text-center">
                                            <h1><span class="text-maryjane text-4xl">4.8% </span>approval rating</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="bg-black">
                                        <div
                                            class="relative -top-2 -left-2 bg-ocean border-2 border-black px-10 sm:px-0  w-64 sm:w-64 py-2 sm:py-6 text-center">
                                            <h1>competitive <span class="text-maryjane">prices</span> and many surprise
                                                <span class="text-maryjane">promos</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-2 sm:pl-16 pr-2 text-justify sm:pr-14 pt-10">
                                <div>
                                    <h1><span class="text-4xl text-red-700">Nusa Vacation</span> adalah sebuah travel
                                        agent yang berbasis di Pulau
                                        Sumbawa, Indonesia. Sebagai travel agent, Nusa Vacation menyediakan
                                        berbagai macam kegiatan wisata yang menarik dan wilayahnya mencakup
                                        seluruh Indonesia. Nusa Vacation bertujuan untuk memberikan
                                        pengalaman wisata yang tak terlupakan kepada para pelanggannya
                                        dengan menawarkan paket perjalanan yang lengkap dan terorganisir
                                        dengan baik.
                                        Dengan pengalaman sejak 2018, Nusa Vacation telah membangun
                                        reputasi sebagai travel agent yang dapat diandalkan dan berkualitas. kami
                                        berkomitmen untuk memberikan pelayanan terbaik kepada pelanggan,
                                        membantu mereka merencanakan perjalanan yang tak terlupakan lebih
                                        khusunya wilayah Bali, Nusa Tenggara Barat, dan Nusa Tenggara Timur.
                                        Kami memiliki pemahaman mendalam tentang destinasi wisata lokal
                                        dan pengalaman unik yang dapat ditawarkan. Wilayah timur Indonesia
                                        terkenal dengan pantai-pantainya yang memukau, pemandangan gunung
                                        yang menakjubkan, serta terumbu karang yang spektakuler untuk
                                        kegiatan menyelam dan snorkeling. Kami berkomitmen untuk memberikan
                                        pengalaman perjalanan yang tak terlupakan kepada setiap pelanggan
                                        kami.</h1>
                                </div>
                                <div class="pt-10 pb-10 sm:pb-0">
                                    <div>
                                        <h1>Location <span class="pl-5">: Jl. Soekarno Hatta No 03 Rt 03 Rw 02
                                                Rabangodu
                                                Utara Kota Bima Provinsi Nusa
                                                Tenggara Barat</span></h1>
                                    </div>
                                    <div>
                                        <h1>
                                            Email <span class="pl-10"> : nusavacation.id@gmail.com</span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1>
                                            Instagram <span class="pl-3"> : @nusavacation</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>