<template>
  <div>
    <div class="flex justify-center px-1 md:px-0">
      <div class="font-Karantina font-extrabold text-2xl sm:text-3xl xl:text-6xl 3xl:text-5xl text-justify">
        <h1 class="w-auto text-center px-4 sm:px-10 md:px-28 xl:px-48 3xl:px-60">FIND YOUR BEST PACKAGE, AND MAKE
          UNFORGETTABLE
          MOMENTS WITH<span class="text-ocean text-3xl sm:text-5xl md:text-6xl"> NUSA VACATION.</span></h1>
        <div class="pr-6 sm:pr-0 pt-6 md:pt-10 3xl:pt-16 text-4xl flex justify-center">
          <Button></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/buttonX.vue';

export default {
  components: {
    Button
  },
};
</script>