import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomePage";
import store from "@/services/store";
import detail from "@/views/PackageDetails";
import packageBar from "@/views/PackagePage";
import gallery from "@/views/GalleryPage";
import contact from "@/views/ContactsPage";
import about from "@/views/AboutPage";
import fnQ from "@/views/faqAndGuidePage"
import service from "@/views/ourServicesPage.vue";
// import adminDashbord from "@/views/adminDashboard";
// import adminTask from "@/views/adminTask";
// import adminTransaction from "@/views/AdminTransactionValidation"

const routes = [
    {
        path: "/f&q",
        name: "Faq & Guides",
        component: fnQ,
        meta: {
            title: "Faq & Guides",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/services",
        name: "Services",
        component: service,
        meta: {
            title: "Services",
            authRequired: false,
            authForbidden: false
        }
    },
    // {
    //     path: "/adminTask",
    //     name: "Admin Task",
    //     component: adminTask,
    //     meta: {
    //         title: "Admin Task",
    //         authRequired: false,
    //         authForbidden: false
    //     }
    // },
    // {
    //     path: "/adminTransaction",
    //     name: "Admin Transaction",
    //     component: adminTransaction,
    //     meta: {
    //         title: "Admin Transaction",
    //         authRequired: false,
    //         authForbidden: false
    //     }
    // },
        // {
    //     path: "/admin",
    //     name: "Admin Dashboard",
    //     component: adminDashbord,
    //     meta: {
    //         title: "Admin Dashboard",
    //         authRequired: false,
    //         authForbidden: false
    //     }
    // },
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: "Nusa Vacation",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: '/detail/:idx',
        name: 'detail',
        component: detail,
        meta: {
            title: "Details Package",
            authRequired: false,
            authForbidden: false
        },
    },
    {
        path: "/package",
        name: "Package",
        component: packageBar,
        meta: {
            title: "Package",
            authRequired: false,
            authForbidden: false
        },
    },
    {
        path: "/imgCollections",
        name: "Img Collection",
        component: gallery,
        meta: {
            title: "Img Collection",
            authRequired: false,
            authForbidden: false
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: contact,
        meta: {
            title: "Contact",
            authRequired: false,
            authForbidden: false
        },
    },
    {
        path: "/about",
        name: "About",
        component: about,
        meta: {
            title: "About",
            authRequired: false,
            authForbidden: false
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    store.commit('setLoading', true);
    if (!store.state.isStoreUpdated) {
        await store.dispatch("updateStore");
    }

    const isAuthenticated = store.state.userLoggedIn;
    if (!isAuthenticated && to.meta.authRequired) {
        next({ name: "login" });
    } else if (isAuthenticated && to.meta.authForbidden) {
        next({ name: "user" });
    } else {
        next();
    }
});

router.afterEach((to) => {
    document.title = to.meta.title;
if(document.title !== "Details Package" && document.title !== "Img Collection"){
    setTimeout(() => {
        store.commit('setLoading', false);
      }, 1000);
}else{
    setTimeout(() => {
        store.commit('setLoading', false);
      }, 3000);
}
});

export default router;