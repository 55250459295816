<template>
    <div class="px-1 sm:px-2 py-1 sm:py-2">
        <div class="bg-sunny border-l-4 border-r-4 border-t-4 border-black">
            <div class="-mt-9 sm:-mt-4 md:-mt-0">
                <navGateLogin />
            </div>

            <div class="pt-2">
                <service />
            </div>

        </div>
        <div class="bg-sunny h-auto border-l-4 border-r-4 border-b-4 border-black">
      <fooTing class="pt-6 pb-2"/>
    </div>
    </div>
</template>
    
<script>
import navGateLogin from "@/components/navigationPage.vue";
import fooTing from '@/components/fooTing.vue';
import service from '@/components/ourService.vue';

export default {
    components: {
        navGateLogin,
        fooTing,
        service
    },
    mounted(){
        this.scrollToTop()
    },
    methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
}
}
</script>