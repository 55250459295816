<template>
    <div class="bg-sunny">
        <div class="flex justify-center">
            <div class="bg-black w-60 sm:w-96">
                <div class="bg-sunny border-2 border-black relative -top-2 -left-2 py-2 sm:py-4 text-center font-Karantina text-lg sm:text-2xl 3xl:text-3xl font-medium tracking-widest">
                    <h1>Copyright @2k20 - Nusa Vacation</h1>
                </div>
            </div>
        </div>
        <div class="flex justify-center space-x-8 py-8">
            <div class="bg-black w-12 transition-transform duration-300 ease-in-out transform hover:scale-125">
                <div class="bg-sunny relative -top-2 -left-2 border-2 border-black">
                    <button class="pl-1 pt-1">
                        <a href="https://www.instagram.com/nusavacation/">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 sm:w-9 h-6 sm:h-9" viewBox="0 0 512 512">
                        <path
                            d="M336 96c21.2 0 41.3 8.4 56.5 23.5S416 154.8 416 176v160c0 21.2-8.4 41.3-23.5 56.5S357.2 416 336 416H176c-21.2 0-41.3-8.4-56.5-23.5S96 357.2 96 336V176c0-21.2 8.4-41.3 23.5-56.5S154.8 96 176 96h160m0-32H176c-61.6 0-112 50.4-112 112v160c0 61.6 50.4 112 112 112h160c61.6 0 112-50.4 112-112V176c0-61.6-50.4-112-112-112z" />
                        <path
                            d="M360 176c-13.3 0-24-10.7-24-24s10.7-24 24-24c13.2 0 24 10.7 24 24s-10.8 24-24 24zM256 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64m0-32c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z" />
                        
                    </svg>
                </a>
                    </button>
                </div>
            </div>
            <div class="bg-black w-12 transition-transform duration-300 ease-in-out transform hover:scale-125">
                <div class="bg-sunny relative -top-2 -left-2 border-2 border-black">
                    <button class="pl-1 pt-1">
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 sm:w-9 h-6 sm:h-9" viewBox="0 0 24 24" version="1.1">
 <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
</svg>
                </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>