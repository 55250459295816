<template>
  <div v-if="isLoading"
    class="loading-overlay font-Karantina text-base sm:text-xl md:text-5xl font-bold uppercase text-maryjane">
    <i class="fas fa-spinner fa-spin"></i><span class="pl-4">Loading...</span>
  </div>
  <router-view />
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "App",
  computed: {
    ...mapState(['isLoading'])
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');

#app {
  font-family: Poppins, serif;
}

#app button {
  font-family: Poppins, serif;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
