<template>
    <div v-show="pop" class="flex md:hidden">
        <div class="fixed inset-0 items-center bg-black bg-opacity-75 z-50 pt-10">
            <div class="bg-sunny border-4 border-black w-[300px] mx-auto">
                <div class="bg-ocean flex border-b-2 border-black justify-between px-4">
                    <div>
                        <h1 class="font-Karantina text-3xl capitalize text-center py-2 text-sunny">pop up Blocked issue.
                        </h1>
                    </div>
                    <div>
                        <div @click="fals" class="pt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6 stroke-[5px] stroke-sunny transition-transform duration-300 ease-in-out transform hover:scale-125">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="py-4">
                    <h1 class="font-saira text-sm text-center">To solve the blocked pop up when trying to get the payment tab, please allow your pop settings by clicking "always show" on the notification, as shown in the red circle on the image.</h1>
                </div>
                <div class="flex justify-center pb-6">
                    <img class="w-[200px] h-[350px] border-2 border-black" src="../assets/popUp.jpg" alt="">
                </div>
                <div class="flex justify-center pb-6 transition-transform duration-300 ease-in-out transform hover:scale-125">
                    <button @click="close" class="bg-red-700 border-2 border-black text-sunny">
                        <h1 class="font-Karantina text-2xl px-6">Closed</h1> 
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pop :true
        }
    },
    methods: {
        fals() {
            this.pop = false
        },
        close() {
            this.pop = false
        }
    }
}
</script>