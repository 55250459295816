<template>
    <div class="pr-10">
        <book ref="bookForm" v-show="shw"></book>
    </div>
    <div>
        <div class="flex space-x-4 md:space-x-10">
            <div class="bg-black transition-transform duration-300 Sease-in-out transform hover:scale-110">
                <div>
                    <button @click="opens" :disabled="loading" class=" px-2 sm:px-5 py-2 bg-maryjane relative -left-2 -top-2 border-2 border-black">
                        <h1 class="font-Karantina text-base sm:text-xl md:text-3xl font-bold uppercase">
                            <span v-if="loading" class="mr-2"><i class="fas fa-spinner fa-spin"></i></span> 
                            {{ loading ? 'Loading...' : 'Book a Package' }}
                        </h1>
                    </button>
                </div>
            </div>
            <RouterLink to="/f&q">
                <div class="bg-black transition-transform duration-300 ease-in-out transform hover:scale-110">
                    <button class=" px-2 sm:px-7 py-2 bg-ocean relative -left-2 -top-2 border-2 border-black">
                        <h1 class="font-Karantina text-base sm:text-xl md:text-3xl font-bold text-sunny">
                            FAQ & GUIDES
                        </h1>
                    </button>
                </div>
            </RouterLink>
        </div>
    </div>
</template>

<script>
import book from "./overBookPack.vue"
export default {
    components: {
        book
    },
    data() {
        return {
            shw: false,
            loading: false
        }
    },
    methods: {
        opens() {
            if (this.loading) return;
            this.loading = true;
            setTimeout(() => {
                this.shw = true;
                this.loading = false;
                this.$refs.bookForm.typePay = true;
            }, 2000);
        }
    }
}
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
</style>
